import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngxs/store";
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { BehaviorSubject, debounceTime, Subscription } from "rxjs";
import { AppState } from "~/core/store";
import { RegionSettings } from "~/core/store/regionalSettings/regionalSettings.model";

import {
    SortOptions,
    SearchStateFilter,
} from "~/core/store/search/search.model";
import { SearchService } from "~/core/store/search/search.service";

@AutoUnsubscribe()
@Component({
    selector: "volvo-stock-options",
    templateUrl: "./stock-options.component.html",
    styleUrls: ["./stock-options.component.scss"],
})
export class StockOptionsComponent implements OnInit, OnDestroy {
    @Input() isVehicleFound = false;
    isChanged: boolean;
    SortOptions = SortOptions;
    selectedOption: SortOptions;
    totalTrucks = 0;
    finalCount: number; // Add this for the final count
    private totalTrucks$ = new BehaviorSubject<number>(0); // Use BehaviorSubject for observability
    options = [
        {
            id: SortOptions.oldestAge,
            label: "New in stock",
        },
        {
            id: SortOptions.highestPrice,
            label: "Highest Price",
        },
        {
            id: SortOptions.lowestPrice,
            label: "Lowest Price",
        },
    ];

    search: SearchStateFilter;

    private s1: Subscription;
    regionalSettings: RegionSettings;

    constructor(public searchService: SearchService, private store: Store) {
        const region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
        this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === region)[0]);
        this.initSubscriptions();
        this.totalTrucks$.pipe(
            debounceTime(1000) // Wait for 1 second after the last update
        ).subscribe(finalValue => {
            this.finalCount = finalValue; // Set the final count
        });
    }

    ngOnInit(): void {
        this.searchService.totalTrucks$.subscribe(value => {
            this.totalTrucks = value;
            this.totalTrucks$.next(value); // Update observable with new value
        });
     }

    ngOnDestroy(): void { }

    onSortChange(sortOptions: SortOptions): void {
        this.searchService.updateSearch({
            ...this.search,
            sort: sortOptions,
        });
    }

    private initSubscriptions(): void {
        this.s1 = this.searchService.filter$.subscribe(
            (searchOptions: SearchStateFilter) => {
                this.search = searchOptions;
                this.selectedOption = this.search.sort;
            }
        );
    }
}
